import React from 'react'
import s from '@emotion/styled'

const color = {
  darkprim0: '#242533',
  darkprim20: '#38394b',
  darkprim40: '#484964',
  darkprim60: '#57576c',
  darkprim80: '#808192',
  darkprim90: '#abacbe',
  lightprim0: '#eff0fb',
  accent100: '#5754f7',
}

export default React.memo(() => {
  return <Container>
           <Title>
             This website is under maintenance...
           </Title>
           <SubTitle>
             If you have any questions, contact me via email: glazer.nicholas@gmail.com
           </SubTitle>
         </Container>
})

const Title = s.div`
color: ${color.darkPrim80};
margin-bottom: 48px;
`
const SubTitle = s.div`
color: ${color.lightPrim0};
`

const Container = s.div`
height: 100vh;
width: 100wh;
background: ${color.darkPrim0};
display: flex;
justify-content: center;
align-items: center;
`
